@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Custom CSS */
  .hero-title {
    line-height: 3.5rem !important;
   }

  

   @media screen and (max-width: 768px) {
    .hero-title {
      line-height: normal !important;
     }

     .hero-title br{
     display: none;
     }

     #mobile-nav {
      position: absolute;
      top: 65px;
      width: 100%;
      left: 0;
      background: #e9e9e9;
      padding: 1rem;
      text-align: center;
     }
   }
